export const environment = {
    name: 'testing',
    production: false,
    baseUrl: 'https://testing-api.alii.care',
    showSsoButton: false,
    hospitalId: 26,
    hospitalName: 'Parnassia',
    appUrl: 'https://testing-api.alii.care',
    froala: {
        key: 'CTD5xC1B2B2C2A17B8wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6D2A1F4E1D1==',
        saveURL: 'https://testing-api.alii.care/api/protocol/par/updateParText.vm',
        imageUploadURL: 'https://us-central1-alii-ea36b.cloudfunctions.net/app/upload'
    },
    firebase: {
        apiKey: 'AIzaSyBrkDA-EMBKdmWeCuWvyRM0o3NR5F6kOyo',
        projectId: 'alii-ea36b',
        storageBucket: 'gs://alii-ea36b.appspot.com'
    },
    ga: '',
    featureFlags: {
        typeaheadMaxOptions: 10,
        outcomesSideBySide: true,
        outcomesShowToggleView: false
    },
    blazorUrl: 'https://testing.model.alii.care/'
};
